import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPlayerstatsNGFBByPlayerId } from "../../redux/selectors/playerstatsSelectors";
import { getPlayerstatsNGFBByPlayerId } from "../../redux/actions/playerstatsActions";
import "../../css/nongame_stats.css";
import { Layout, Table } from "antd";
import { formatRelative } from "date-fns";
import { nongamestats } from "../../shared-components/categories_fb";

const NongameStats = ({ playerId }) => {
  let data = useSelector(selectPlayerstatsNGFBByPlayerId(playerId));
  const loading = useSelector(store => store.playerstatsData.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (playerId) {
      dispatch(getPlayerstatsNGFBByPlayerId(playerId));
    }
  }, [playerId]);

  const columns = [
    {
      title: "Stat",
      dataIndex: "stat",
      key: "stat",
      render(text, row) {
        const statLabel = nongamestats?.find(stat => stat?.stat === text);

        return statLabel ? statLabel.label : text;
      },
    },
    {
      title: "Most Recent Value",
      dataIndex: "latest_value",
      key: "latest_value",
    },
    { title: "Date Recorded", dataIndex: "displayDate", key: "displayDate" },
    {
      title: "Goal",
      dataIndex: "target",
      key: "target",
      render(text, row) {
        return text ?? "N/A";
      },
    },
  ];

  if (data) {
    data = data.map(s => {
      return {
        ...s,
        date_recorded: new Date(s?.date_recorded),
        displayDate: formatRelative(new Date(s?.date_recorded), new Date()),
      };
    });

    return (
      <Layout.Content style={{ marginLeft: 0, marginRight: 30, marginTop: 15 }}>
        <Table
          dataSource={data}
          rowKey="stat"
          columns={columns}
          loading={loading}
          pagination={false}
        />
      </Layout.Content>
    );
  } else return null;
};

export default NongameStats;