import React, { useEffect } from "react";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecruiterReferrals,
  selectReferrals,
  selectReferralsLoading,
} from "../../redux/referral_slice";
import { Link } from "@reach/router";
import PlayerTable from "../../shared-components/PlayerTable";
import moment from "moment";

const RecruiterReferrals = props => {
  const referralData = useSelector(selectReferrals);
  const loading = useSelector(selectReferralsLoading);

  const data = referralData
    ? referralData.map(r => {
        return {
          ...r,
          playerLink: (
            <Link to={`/referrals/players/${r.playerId}`}>{r.playerName}</Link>
          ),
        };
      })
    : [];

  const dispatch = useDispatch();

  useEffect(() => {
    // no data check here to make the last date column update whenever the page loads in
    if (!loading) {
      dispatch(getRecruiterReferrals());
    }
  }, []);

  const columns = [
    {
      title: "Player",
      dataIndex: "playerName",
    },
    {
      title: "State",
      dataIndex: "state",
      filterable: true,
    },
    {
      title: "School",
      dataIndex: "schoolName",
    },
    {
      title: "Coaches",
      dataIndex: "coaches",
    },
    {
      title: "Jersey",
      dataIndex: "jersey",
    },
    {
      title: "Position",
      dataIndex: "position",
      filterable: true,
    },
    {
      title: "Graduating Class",
      dataIndex: "graduationYear",
      filterable: true,
    },
    {
      title: "Height",
      dataIndex: "displayHeight",
      filterable: true,
    },
    {
      title: "Weight",
      dataIndex: "weight",
      filterable: true,
    },
    {
      title: "Hudl Link",
      dataIndex: "link",
    },
    {
      title: "Last viewed",
      dataIndex: "lastView",
      render(text, render) {
        return <div>{moment(new Date(text)).format("M/D/YYYY h:mm a")}</div>;
      },
    },
  ];

  return (
    <Layout style={{ minWidth: "1100px" }}>
      <Layout.Content style={{ marginLeft: 0, marginRight: 30, marginTop: 15 }}>
        <h1>Referred Players</h1>
      </Layout.Content>
      <Layout.Content style={{ marginLeft: 0, marginRight: 30, marginTop: 15 }}>
        <PlayerTable
          playerData={data}
          columns={columns}
          loading={loading}
          navBase={"/referrals"}
        />
      </Layout.Content>
    </Layout>
  );
};

export default RecruiterReferrals;
