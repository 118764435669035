import React, { useEffect } from "react";
import { Badge, Col, Layout, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoachReferrals,
  selectReferrals,
  selectReferralsLoading,
} from "../../redux/referral_slice";
import { Link } from "@reach/router";
import PlayerTable from "../../shared-components/PlayerTable";
import moment from "moment";
import { useLocation } from "@reach/router";
import { selectSeasonsLoading } from "../../redux/season_slice";
import { parseQueryString } from "../../shared-components/queryStringUtils";
import RosterTabs from "../coach/components/RosterTabs";

const CoachReferrals = props => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { rosterId } = parseQueryString(location.search);

  // seasons
  const seasonsLoading = useSelector(selectSeasonsLoading);
  const teamsLoading = useSelector(store => store.rosterData.loadingTeams);

  // referrals selectors
  const referralData = useSelector(selectReferrals);
  const referralsLoading = useSelector(selectReferralsLoading);

  useEffect(() => {
    // no data check here to make the last date column update whenever the page loads in
    if (rosterId) {
      dispatch(getCoachReferrals(rosterId));
    }
  }, [rosterId]);

  console.log("referralData", referralData);

  const data = referralData
    ? referralData.map(r => {
        return {
          ...r,
          referralUserName: r.coachName || r.recruiterName,
          referralSchoolName: r.coachSchoolName || r.recuiterSchoolName,
          playerLink: (
            <Link to={`/referrals/players/${r.playerId}`}>{r.playerName}</Link>
          ),
        };
      })
    : [];

  const columns = [
    {
      title: "Player",
      dataIndex: "playerName",
    },
    {
      title: "Last Viewed by",
      dataIndex: "referralUserName",
      render(text, row) {
        if (row?.referralSchoolName && row?.referralUserName) {
          return `${row.referralUserName} from ${row?.referralSchoolName}`;
        } else if (row?.referralUserName) {
          return text;
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "School",
      dataIndex: "referralSchoolName",
      hidden: true,
      render(text, row) {
        return text ?? "N/A";
      },
    },
    {
      title: "Graduating Class",
      dataIndex: "graduationYear",
      filterable: true,
    },
    { title: "View Count", dataIndex: "viewCount" },
    {
      title: "Last viewed at",
      dataIndex: "lastView",
      render(text, render) {
        return <div>{moment(new Date(text)).format("M/D/YYYY h:mm a")}</div>;
      },
    },
  ];

  const expandedRowRender = row => {
    // console.log("stuff", stuff);
    const columns = [
      { title: "", width: "18%" },
      {
        title: "Name",
        dataIndex: "referralUserName",
        render(text, row) {
          return text ?? "N/A";
        },
        width: "20%",
      },
      {
        title: "School",
        dataIndex: "referralSchoolName",
        render(text, row) {
          return text ?? "N/A";
        },
        width: "24.5%",
      },
      { title: "View Count", dataIndex: "viewCount", width: "16.5%" },
      {
        title: "Last viewed at",
        dataIndex: "lastView",
        render(text, render) {
          return <div>{moment(new Date(text)).format("M/D/YYYY h:mm a")}</div>;
        },
      },
    ];

    const nestedReferralsData = referralData
      ?.find(referral => referral?.playerId === row?.playerId)
      ?.referrals?.map(referral => ({
        ...referral,
        referralUserName: referral?.coachName || referral?.recruiterName,
        referralSchoolName:
          referral?.coachSchoolName || referral?.recuiterSchoolName,
      }));

    return (
      <Table
        columns={columns}
        dataSource={nestedReferralsData}
        pagination={false}
      />
    );
  };

  return (
    <Layout style={{minWidth: "600px"}}>
      <RosterTabs rosterId={rosterId} hideInactive={true} hideScouting={true} />
      <Layout.Content style={{ marginLeft: 0, marginRight: 30, marginTop: 15 }}>
        <h1>Referred Players</h1>
      </Layout.Content>
      <Layout.Content style={{ marginLeft: 0, marginRight: 30, marginTop: 15 }}>
        <Col xs={24} lg={18}>
        <PlayerTable
          playerData={data}
          columns={columns}
          loading={seasonsLoading || teamsLoading || referralsLoading}
          expandable={{
            expandedRowRender,
          }}
          navBase={"/referrals"}
        />
        </Col>
      </Layout.Content>
    </Layout>
  );
};

export default CoachReferrals;
