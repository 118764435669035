import { Grid } from "antd";

const { useBreakpoint } = Grid;

const useResponsiveStyles = ({
  xs = {},
  sm = {},
  md = {},
  lg = {},
  xl = {},
  xxl = {},
  ...defaultStyles
}) => {
  const breakpoints = useBreakpoint();

  return {
    ...defaultStyles,
    ...(breakpoints.xs ? xs : {}),
    ...(breakpoints.sm ? sm : {}),
    ...(breakpoints.md ? md : {}),
    ...(breakpoints.lg ? lg : {}),
    ...(breakpoints.xl ? xl : {}),
    ...(breakpoints.xxl ? xxl : {}),
  };
};

export default useResponsiveStyles;
