import React, { useEffect, useState } from "react";
import { Button, Col, Layout, Row, Select, Space, Tabs, Tooltip } from "antd";
import { useLocation, useNavigate } from "@reach/router";
import * as queryString from "query-string";
import RosterTabs from "./components/RosterTabs";
import TeamSchedule from "./components/TeamSchedule";
import {
  createOrUpdateCustomAnalyticsFilter,
  deleteCustomAnalyticsFilter,
} from "../../redux/customAnayticsFilter_slice";
import { dispatch } from "../../redux";
import {
  FBAnalyticFilterSelect,
  FBAnalyticGroupingsSelect,
  FBAnalyticStatSelect,
  SavedFilterSelect,
} from "./components/customAnalytics/FBAnalyticSelects";
import CustomAnalyticsTable from "./components/customAnalytics/CustomAnalyticsTable";
import HitChart from "./components/customAnalytics/HitChart";
import NameSaveFilterModal from "./components/customAnalytics/NameSaveFilterModal";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { Option } = Select;

export const CustomAnalytics = ({
  rosterId,
  gameId,
  showAddGame,
  allowGameEdit,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedGames, setSelectedGames] = useState([]);
  const [savedFilter, setSavedFilter] = useState(null);
  const [filterNameModal, setFilterNameModal] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("analytics");
  const hideFilterNameModal = () => setFilterNameModal(false);

  const {
    gameIds,
    odk,
    groupingField1,
    groupingField2,
    filterValue,
    extraFilterField,
    extraFilterValue,
    statName,
    tabKey,
  } = queryString.parse(location.search);

  const onChangeQueryParams =
    (params, replace = true) =>
    (selectedIds, selected) => {
      let newQs = queryString.parse(location.search);
      if (selected) {
        setSelectedGames(selected);
      }
      if (Array.isArray(params)) {
        params.forEach((p, i) => {
          newQs = { ...newQs, [p]: selectedIds[i] };
        });
      } else {
        newQs =
          params === "gameIds" && selectedIds.length === 0
            ? { ...newQs, [params]: selectedIds, filterValue: "all" }
            : { ...newQs, [params]: selectedIds };
      }

      return navigate(
        location.pathname +
          "?" +
          queryString.stringify(newQs, { arrayFormat: "comma" }),
        { replace },
      );
    };

  useEffect(() => {
    if (!groupingField1)
      onChangeQueryParams("groupingField1", true)("all_stats");

    if (!groupingField2) onChangeQueryParams("groupingField2", true)("gameId");

    if (!odk) onChangeQueryParams("odk", true)("O");

    if (!filterValue) onChangeQueryParams("filterValue", true)("all");

    if (!statName) onChangeQueryParams("statName", true)("all_plays");

    if (gameId && !gameIds) onChangeQueryParams("gameIds", true)(gameId);
  });

  useEffect(() => {
    if (tabKey) {
      setActiveTabKey(tabKey);
    }
  }, [tabKey]);

  const handleTabChange = key => {
    if (key === "chart") {
      onChangeQueryParams([
        "odk",
        "groupingField1",
        "extraFilterValue",
        "extraFilterField",
        "tabKey",
      ])(["O", "off_formation", "all", "all_stats", key]);
    } else {
      onChangeQueryParams(["tabKey"])([key]);
    }
  };

  const handleSavedFilterSelection = value => {
    if (value !== undefined) {
      let jsonFilterValues = JSON.parse(value.filterValue);

      // Create an array of keys
      let keysArray = Object.keys(jsonFilterValues);

      // Create an array of values
      let valuesArray = Object.values(jsonFilterValues);

      //this is a bit janky, but if I didn't do it the games were not being removed.
      if (!keysArray.includes("gameIds")) {
        keysArray.push("gameIds");
        valuesArray.push([]);
      }
      onChangeQueryParams(keysArray)(valuesArray);
      setSavedFilter(value);
    } else {
      setSavedFilter(null);
    }
  };

  const handleDeleteSaveFilter = () => {
    dispatch(deleteCustomAnalyticsFilter(savedFilter.filterId));
  };

  const handleSaveFilter = (name = null) => {
    const transformCommaDelimited = obj => {
      for (let key in obj) {
        // Check if the property is a string
        if (typeof obj[key] === "string") {
          // Check if the string contains commas
          if (obj[key].includes(",")) {
            // Split the string by commas and trim each part to remove extra spaces
            obj[key] = obj[key].split(",").map(s => s.trim());
          }
        }
      }
      return obj;
    };

    /*
            saved filter has already been selected
         */
    if (savedFilter) {
      const body = {
        filterId: savedFilter.filterId,
        filterName: savedFilter.filterName,
        filterValue: JSON.stringify(
          transformCommaDelimited(queryString.parse(location.search)),
        ),
      };

      dispatch(createOrUpdateCustomAnalyticsFilter(body));
    } else {
      const body = {
        filterName: name,
        filterValue: JSON.stringify(
          transformCommaDelimited(queryString.parse(location.search)),
        ),
      };

      dispatch(createOrUpdateCustomAnalyticsFilter(body));
    }
  };

  return (
    <>
      {/* <Space direction="vertical"> */}
      {!gameId ? (
        <Row>
          <Col xs={24} lg={tabKey === "chart" ? 24 : 18}>
            <TeamSchedule
              rosterId={rosterId}
              showAddGame={showAddGame}
              allowGameEdit={allowGameEdit}
              rowSelection={{
                selectedRowKeys:
                  gameIds && !Array.isArray(gameIds)
                    ? gameIds.split(",")
                    : gameIds,
                onChange: onChangeQueryParams("gameIds"),
              }}
              includeColorKeyColumn={tabKey === "chart" ? true : false}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Space style={{ padding: 10 }}>
        Filters:
        <SavedFilterSelect onChange={handleSavedFilterSelection} />
      </Space>
      <Space>
        <Button
          type={"primary"}
          onClick={
            savedFilter ? handleSaveFilter : () => setFilterNameModal(true)
          }>
          {savedFilter ? "Update Saved Filter" : "Create Saved Filter"}
        </Button>
        {savedFilter ? (
          <>
            <Tooltip title="Delete Saved Filter">
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={handleDeleteSaveFilter}></Button>
            </Tooltip>
            <Tooltip title="Edit Saved Filter Name">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => setFilterNameModal(true)}></Button>
            </Tooltip>
          </>
        ) : null}
      </Space>
      <Space wrap>
        Side:
        <Select
          placeholder="Select..."
          width={200}
          defaultValue={odk}
          value={odk}
          onChange={onChangeQueryParams("odk")}
          disabled={tabKey === "chart"}>
          <Option key="O" value="O">
            Offensive Plays
          </Option>
          <Option key="D" value="D">
            Defensive Plays
          </Option>
        </Select>
        Breakdown:
        <FBAnalyticGroupingsSelect
          onChange={v => {
            onChangeQueryParams(["filterValue", "groupingField1"])(["all", v]);
          }}
          value={groupingField1}
          width={200}
          includeAll={true}
          disabled={tabKey === "chart"}
        />
        {groupingField1 !== "all_stats" ? (
          <>
            Filter:
            <FBAnalyticFilterSelect
              onChange={onChangeQueryParams("filterValue")}
              values={filterValue}
              width={200}
              gameIds={gameIds}
              odk={odk}
              groupingField1={groupingField1}
              groupingField2={groupingField2}
            />
          </>
        ) : (
          <></>
        )}
        {tabKey !== "chart" ? (
          <>
            Columns:
            <FBAnalyticGroupingsSelect
              onChange={onChangeQueryParams("groupingField2")}
              value={groupingField2}
              width={200}
            />
            Extra Filter:
            <FBAnalyticGroupingsSelect
              onChange={v => {
                onChangeQueryParams(["extraFilterValue", "extraFilterField"])([
                  "all",
                  v,
                ]);
              }}
              value={extraFilterField}
              width={200}
              excludeGame={true}
              includeAll={true}
            />
            <FBAnalyticFilterSelect
              onChange={onChangeQueryParams("extraFilterValue")}
              values={extraFilterValue}
              width={200}
              gameIds={gameIds}
              odk={odk}
              groupingField1={extraFilterField}
              groupingField2={groupingField2}
            />
          </>
        ) : null}
        {groupingField1 === "all_stats" || tabKey !== "analytics" ? (
          <></>
        ) : (
          <>
            Stat:
            <FBAnalyticStatSelect
              onChange={onChangeQueryParams("statName")}
              value={statName}
              width={200}
            />
          </>
        )}
      </Space>
      <NameSaveFilterModal
        isVisible={filterNameModal}
        filter={savedFilter ? savedFilter : null}
        onHandle={() => hideFilterNameModal()}
        handleCreate={input => handleSaveFilter(input)}
      />
      <Tabs
        onChange={handleTabChange}
        activeKey={activeTabKey}
        style={{ marginBottom: "50px" }}>
        <TabPane tab="Analytics Table" key="analytics">
          <Col xs={24}>
            <CustomAnalyticsTable
              gameIds={gameIds}
              odk={odk}
              groupingField1={groupingField1}
              groupingField2={groupingField2}
              filterValue={filterValue}
              extraFilterField={extraFilterField}
              extraFilterValue={extraFilterValue}
              statName={statName}
            />
          </Col>
        </TabPane>
        <TabPane tab="Hit chart" key="chart">
          <HitChart
            gameIds={gameIds}
            odk={odk}
            groupingField1={groupingField1}
            groupingField2={groupingField2}
            filterValue={filterValue}
            rosterId={rosterId}
          />
        </TabPane>
      </Tabs>
      {/* </Space> */}
    </>
  );
};

const CustomAnalyticsScreen = props => {
  const location = useLocation();
  const { rosterId, hideRosters, showAddGame, allowGameEdit } =
    queryString.parse(location.search);

  return (
    <div>
      <Layout style={{ minWidth: "700px" }}>
        <Layout.Content>
          <RosterTabs
            rosterId={rosterId}
            hideInactive={true}
            hideScouting={true}
            hideRosters={hideRosters}
          />
          <CustomAnalytics
            rosterId={rosterId}
            showAddGame={showAddGame}
            allowGameEdit={allowGameEdit}
          />
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default CustomAnalyticsScreen;
