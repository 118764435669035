import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Space,
  Table,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGotLists,
  selectLists,
  selectLoading,
} from "../../redux/selectors/recruiterlistSelectors";
import {
  createList,
  deleteList,
  getLists,
  updateList,
} from "../../redux/actions/recruiterlistActions";
import { EditableCell, sorter } from "../../shared-components/PlayerTable";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { formatRelative } from "date-fns";
import { Link } from "@reach/router";

const NewListModal = ({ isVisible, onHandle }) => {
  const dispatch = useDispatch();
  const [listName, setListName] = useState();

  const okCancel = ok => () => {
    if (ok && listName && listName.trim() !== "")
      dispatch(createList({ listName, playerIds: [] }));

    setListName(null);
    onHandle();
  };

  return (
    <Modal
      title="New List"
      visible={isVisible}
      onOk={okCancel(true)}
      onCancel={okCancel(false)}
      destroyOnClose={true}>
      <h3>List Name:</h3>
      <Input
        placeholder={"List Name"}
        onChange={e => setListName(e.target.value)}
        autoFocus
        onPressEnter={okCancel(true)}
      />
    </Modal>
  );
};

const SavedLists = ({ onChange, width, mode = "default" }) => {
  let listData = useSelector(selectLists);
  const gotLists = useSelector(selectGotLists);
  const loading = useSelector(selectLoading);

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const hideModal = () => setModalVisible(false);

  const edit = record => {
    form.setFieldsValue(record);
    setEditingKey(record.listId);
  };

  const saveRow = async record => {
    try {
      const newData = await form.validateFields();
      dispatch(
        updateList(record.listId, { listName: newData.listName }, getLists),
      );
      setEditingKey("");
      message.success("List name updated!", 2);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  useEffect(() => {
    if (!gotLists && !loading) dispatch(getLists());
  });

  if (listData) {
    listData = listData
      .map(l => {
        return {
          ...l,
          listLink: <Link to={`./${l.listId}`}>{l.listName}</Link>,
        };
      })
      .map(l => {
        if (l.lastUpdate) {
          return {
            ...l,
            lastUpdate: new Date(l.lastUpdate),
            lastUpdateDisplay: formatRelative(
              new Date(l.lastUpdate),
              new Date(),
            ),
          };
        } else {
          return l;
        }
      });
  }

  const columns = [
    {
      title: "List Name",
      dataIndex: "listLink",
      sorter: sorter("listName"),
      sortDirections: ["ascend", "descend"],
      onCell: record => ({
        record,
        title: "List Name",
        dataIndex: record.listId === editingKey ? "listName" : "listLink",
        editing: record.listId === editingKey,
      }),
    },
    {
      title: "Last Update",
      dataIndex: "lastUpdateDisplay",
      sorter: sorter("lastUpdate"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "# Players",
      dataIndex: "playerCount",
      sorter: sorter("playerCount"),
      sortDirections: ["ascend", "descend"],
    },
    {
      dataIndex: "listId",
      render: (listId, record) => {
        if (listId === editingKey) {
          return (
            <Space>
              <Button
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => saveRow(record)}
              />
              <Button
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => setEditingKey("")}
              />
            </Space>
          );
        } else {
          return (
            <Space>
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => dispatch(deleteList(listId))}
              />
            </Space>
          );
        }
      },
    },
  ];

  return (
    <Layout style={{ minWidth: "450px" }}>
      <Layout.Content style={{ marginLeft: 0, marginRight: 30, marginTop: 15 }}>
        <h1>Saved Lists</h1>
        <Button type={"primary"} onClick={() => setModalVisible(true)}>
          New List
        </Button>
      </Layout.Content>
      <Layout.Content style={{ marginLeft: 0, marginRight: 30, marginTop: 15 }}>
        <Col xs={24} lg={18}>
          <Form form={form} component={false}>
            <Table
              dataSource={listData}
              rowKey="listId"
              columns={columns}
              loading={loading}
              pagination={false}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
            />
          </Form>
        </Col>
      </Layout.Content>
      <NewListModal isVisible={modalVisible} onHandle={hideModal} />
    </Layout>
  );
};

export default SavedLists;
